.admin-panel-left-column {
  width: 20%;
  float: left;
  padding: 4% 0 5% 5%;
}

.admin-panel-left-column .Link:hover {
  color: #c52d12;
}


.admin-panel-right-column {
  width: 80%;
  float: left;
  padding: 4% 5% 5% 0;
}

@media (max-width: 768px) {
  .admin-panel-left-column {
    width: 100%;
  }

  .admin-panel-right-column {
    width: 100%;
    float: left;
    padding: 4% 0 5% 0;
  }
}
