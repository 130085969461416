.basket-left-column {
  float: left;
  width: 55%;
}

.basket-table {
  width: 100%;
  text-align: left;
}

.basket-table img {
  width: 100px;
}

.basket-table p {
  color: gray;
}

.basket-table b {
  color: black;
}

.basket-table .quantity select {
  width: 50%;
}

.basket-table .total {
  text-align: right;
}

.basket-total {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
}

.basket-right-column {
  float: left;
  text-align: center;
  width: 35%;
  margin: 0 0 0 10%;
}

.basket-right-column-step-wrapper {
  border: 1px solid black;
  padding: 5% 2% 5% 2%;
  border-radius: 10px;
}

.basket-title {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 20px;
}

.basket-welcome {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 20px;
}

.basket-input {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .basket-left-column {
    float: left;
    width: 100%;
  }

  .basket-right-column {
    float: left;
    width: 100%;
    margin: 20% 0 10% 0;
  }

  .basket-table .quantity select {
    width: 100%;
  }
}

.basket-fixed-width-td {
  width: 100%; /* Set your desired fixed width */
  word-wrap: break-word; /* Ensures content wraps within the fixed width */
}