.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.footer-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-link {
  color: #fff;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
}

.social-link {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

.social-link:hover {
  color: #c52d12;
}

/* Responsive design */
@media screen and (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-links {
    justify-content: center;
    margin-bottom: 10px;
  }
}
