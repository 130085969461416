.product-card {
  width: 100%;
  /* padding: 20px; */
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.product-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-card h3 {
  font-size: 18px;
  margin: 10px 0;
  height: 2.7em; /* Fixed height for 3 lines */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}

.product-card p {
  font-size: 15px;
  margin-bottom: 20px;
}

.product-card-button {
  background-color: #c52d12;
  color: white;
  border: none;
  padding: 13px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.product-card-button:hover {
  background-color: #a82410;
}

@media (max-width: 768px) {
  .product-card {
    width: 100%;
    margin: 10px 0;
  }
}
