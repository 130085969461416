/* Style for each slide */
.category-slide {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Square image container with centered image */
.image-container {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #ccc;
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Category name styling */
.category-name {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}
