.form-container {
  text-align: center;
}

.form-input {
  margin-bottom: 15px;
  width: 50%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

@media (max-width: 768px) {
  .form-container {
    margin: 15% 0 15% 0%;
  }

  .form-input {
    width: 100%;
  }
}