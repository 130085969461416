/* Home Page Styles */

.home-hero-section {
  /* background: url('https://dalfioreapi.itinerify.com/images/homepage.jpg') no-repeat center center/cover; */
  /* padding: 220px 20px; */
  color: white;
  text-align: center;
  width: 100%;
}

.home-hero-title {
  font-size: 5em;
  font-weight: 800;
  margin-bottom: 10px;
  color: #c52d12;
  margin-left: 5%;
  margin-right: 5%;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.home-hero-subtitle {
  font-size: 2.5em;
  font-weight: 700;
  color: #c52d12;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.home-hero-img {
  width: 35%;
}

@media (max-width: 768px) {
  .home-hero-title {
    font-size: 2.5em;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 10px;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  }
  
  .home-hero-subtitle {
    font-size: 1.2em;
    font-weight: 700;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  }

  .home-hero-img {
    width: 80%;
  }
}

.home-product-showcase {
  margin: 50px 0;
}

.home-product-showcase h2 {
  font-size: 2.2em;
  margin-bottom: 20px;
}

.home-product-showcase .caption {
  font-size: 1.2em;
}

.home-product-showcase .category-title {
  font-size: 1.8em;
  text-align: left;
  margin-bottom: 20px;
}

.home-featured-product {
  margin: 50px 0;
}

.home-featured-product h2 {
  font-size: 2.2em;
  margin-bottom: 20px;
}

.home-featured-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.home-featured-content img {
  width: 40%;
  height: auto;
  border-radius: 10px;
  margin-right: 20px;
}

.home-featured-description {
  width: 50%;
}

.home-featured-description p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .home-featured-content {
    flex-direction: column;
  }

  .home-featured-content img {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .home-featured-description {
    width: 100%;
  }
}

.home-categories-column {
  width: 33.33%;
  float: left;
  padding: 2%;
  border-radius: 10px;
  position: relative;
}

.home-categories-image-container {
  position: relative;
}

.home-categories-column img {
  width: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.home-categories-image-container:hover img {
  transform: scale(1.05);
}

.home-categories-centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Text shadow for readability */
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 1.4em;
  font-weight: bold;
}

@media (max-width: 768px) {
  .home-categories-column {
    width: 100%;
    float: left;
    padding: 5% 5% 0 5%;
    border-radius: 10px;
    position: relative;
  }
}

.home-online-delivery-column {
  float: left;
  width: 33.33%;
  margin-bottom: 2%;
}

.home-online-delivery-column img {
  width: 45%;
  cursor: pointer;
}

@media (max-width: 768px) {
  .home-online-delivery-column {
    width: 100%;
    margin: 5% 0 4% 0;
  }
}

/* HOME MENU CARD */
.home-menu-card {
  width: 33.33%;
  float: left;
  padding: 0 2% 0 2%;
}

.home-menu-card img {
  width: 100%;
  border-radius: 10px;
}

.home-menu-card h3 {
  margin-top: 4%;
  font-size: 25px;
  font-weight: 800;
}

.home-menu-card p {
  margin-top: 2%;
  font-size: 18px;
}

.home-menu-card {
  width: 33.33%;
  float: left;
  padding: 0 2% 0 2%;
}

@media (max-width: 768px) {
  .home-menu-card {
    width: 100%;
    margin: 0 0 10% 0;
  }
}