.menu-container {
  background-color: white;
  border-radius: 20px;
  width: 76%;
  margin: 5% 12% 5% 12%;
}

@media (max-width: 768px) {
  .menu-container {
    background-color: white;
    border-radius: 20px;
    width: 92%;
    margin: 5% 4% 5% 4%;
  }  
}

.menu-title {
  font-size: 50px;
  font-weight: 800;
  margin: 0 0 1% 0;
  text-align: center;
  color: #c52d12;
}

.menu-welcome {
  font-size: 1.2em;
  margin-bottom: 40px;
  text-align: center;
}

.menu-category-name {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 768px) {
  .menu-title {
    margin-top: 2%;
  }

  .menu-welcome {
    margin: 0 5% 0 5%;
  }
}

.menu-column {
  float: left;
  width: 45%;
  margin: 0 2.5% 0 2.5%;
}

.menu-column img {
  width: 100%;
  border-radius: 20px;
}

.menu-column h2 {
  padding: 4% 0 0 0;
}

.menu-column p {
  padding: 1% 0 3% 0;
}

@media (max-width: 768px) {
  .menu-column {
    float: left;
    width: 100%;
    margin: 0 0 3% 0;
    padding: 0 5% 0 5%;
  }
}