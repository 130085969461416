/* Contact Page Styles */

.contact-container {
  padding: 0 4% 0 4%;
}

.contact-description {
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 1.6;
}

.contact-ending {
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 20px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 1.2em;
}

.contact-info-item i {
  font-size: 1.5em;
  margin-right: 10px;
}

.contact-form {
  width: 80%;
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-group {
  width: 100%;
  margin-bottom: 15px;
}

.contact-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.contact-form-group input,
.contact-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Wix Madefor Display', sans-serif;
}

.contact-button {
  background-color: #c52d12;
  color: white;
  border: none;
  padding: 13px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Wix Madefor Display', sans-serif;
  cursor: pointer;
  border-radius: 10px;
}

.contact-button:hover {
  background-color: #a82410;
}

.contact-map-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-map {
  width: 45%;
  margin: 10px 0;
}

.contact-map p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.contact-map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

@media (max-width: 768px) {
  .contact-map {
    width: 100%;
  }
}
