.about-container {
  padding: 0 4% 0 4%;
}

.about-title {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 1%;
  text-align: center;
  color: #c52d12;
}

@media (max-width: 768px) {
  .about-title {
    margin-top: 15%;
  }
}

.about-welcome {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.about-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.about-section.reverse {
  flex-direction: row-reverse;
}

.about-text {
  width: 60%;
}

.about-image-container {
  width: 35%;
  text-align: center;
}

.about-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.about-description {
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 1.6;
}

.about-ending {
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.about-video {
  margin: 40px 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
  }

  .about-section.reverse {
    flex-direction: column;
  }

  .about-text, .about-image-container {
    width: 100%;
  }

  .about-image {
    width: 100%;
    margin-bottom: 20px;
  }
}