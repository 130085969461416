@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');

/* Standard settings for the whole page */
* {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  list-style: none;
  box-sizing: border-box;
  font-family: "Rethink Sans", sans-serif;
}

a {
  text-decoration: none;
  color: #000000;
}

/* Let the page scrool smoothly when button redirects to div */
html {
  scroll-behavior: smooth;
}

/* Prevent users to scroll page left and right */
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Rethink Sans", sans-serif;
  background-color: #f8f8f8;
}

.button {
  background-color: #c52d12;
  color: white;
  border: none;
  padding: 13px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Rethink Sans", sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  border-radius: 10px;
}

.button:hover {
  background-color: #a82410;
}

/* Only display 2 lines */
.max-2-lines-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Only display 1 line */
.max-1-line-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.global-container {
  width: 80%;
  margin: 5% 10% 5% 10%;
}

/* TABLE STYLE */
.table-container {
  overflow-x: auto; /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: "Rethink Sans", sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.table thead tr {
  background-color: #c52d12;
  color: #ffffff;
  text-align: left;
}

.table th,
.table td {
  padding: 12px 15px;
}

.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #c52d12;
}

.table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.table img {
  width: 50px;
}

/* Input types bugged on apple */
input[type="date"]
{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
}

input[type="time"]
{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
}

select
{
  -moz-appearance: none;
  background-color: white;
}

.giglio-line {
  width: 40%;
}

@media (max-width: 768px) {
  .giglio-line {
    width: 80%;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust as needed */
}