.product-left-column {
  width: 50%;
  float: left;
}

.inside-product-swiper-slide img {
  width: 70%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.product-right-column {
  width: 50%;
  float: left;
  margin: 0 0 0 0;
}

.quantity-selector {
  margin-top: 10px;
}

.quantity-input {
  display: flex;
  align-items: center;
}

.quantity-input button {
  background-color: #c52d12;
  width: 40px;
  border: none;
  color: white;
  font-size: 1.2rem;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.quantity-input button:hover {
  background-color: #a82410;
}

.quantity-input input{
  width: 40px;
  text-align: center;
  font-size: 1rem;
  border: 1px solid #ccc;
  margin: 0 5px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
}

.quantity-input select {
  width: 140px;
  text-align: center;
  font-size: 1rem;
  border: 1px solid #ccc;
  padding: 7px;
  outline: none;
  border-radius: 5px;
}

.quantity-input input:focus {
  border-color: #c52d12;
}

.product-quantity-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.product-quantity-button-wrapper button {
  width: 50%;
}

@media (max-width: 768px) {
  .product-left-column {
    width: 100%;
  }
  
  .product-left-column img {
    width: 100%;
  }
  
  .product-right-column {
    width: 100%;
    margin: 10% 0 10% 0;
  }
}