.newsletter-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%; /* Adjust as needed for responsiveness */
  margin: 0 auto; /* Centers the box horizontally */
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc; /* Optional styling for a border */
  border-radius: 10px; /* Optional for rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  background-color: #fff; /* Optional background */
}

.newsletter-box h3 {
  font-size: 30px;
  color: #c52d12;
}

.newsletter-box input {
  width: 100%; /* Full width within the parent */
  max-width: 400px; /* Optional maximum width */
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.newsletter-box img {
  width: 70%;
  margin: 4% 15% 4% 15%;
}

@media (max-width: 768px) {
  .newsletter-box {
    width: 100%; /* Adjust as needed for responsiveness */
    margin: 0 auto; /* Centers the box horizontally */
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc; /* Optional styling for a border */
    border-radius: 10px; /* Optional for rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
    background-color: #fff; /* Optional background */
  }
}