.signup-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
}

.signup-title {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.signup-input {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signup-button {
  background-color: #c52d12;
  color: white;
  border: none;
  padding: 13px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Wix Madefor Display', sans-serif;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 15px;
}

.signup-button:hover {
  background-color: #a1220d;
}

.signup-privacy-policy {
  text-align: left;
  margin-bottom: 15px;
  text-align: center;
}

.signup-privacy-policy input {
  margin-right: 10px;
}

.signup-login-link a {
  color: #c52d12;
}
