.navbar {
  background: #c52d12;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content within navbar */
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 50px 200px; /* Add padding to keep items away from screen edges */
  margin: 2% 2% 2% 2%;
  border-radius: 50px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Center content within a maximum width */
  padding: 0 20px; /* Inner padding for spacing */
}

.navbar-logo img {
  width: 300px; /* Original width for large screens */
  margin-top: 2%;
  cursor: pointer;
}

.menu-icon {
  display: none; /* Hidden by default, shown only on small screens */
  color: white;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-links:hover {
  border-bottom: 2px solid #000000;
}

.nav-social-link {
  color: white;
  font-size: 24px;
  text-decoration: none;
}

.nav-social-link:hover {
  color: white;
}

.navbar-cart {
  display: none;
}

/* Mobile Styling - only applied on small screens */
@media screen and (max-width: 960px) {
  .navbar {
    padding: 0 10px;
    justify-content: space-between; /* Center logo, left-align menu icon, right-align cart */
  }

  .menu-icon {
    display: block;
    z-index: 9999;
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

  .menu-icon-black {
    color: black;
  }

  .navbar-logo {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .navbar-logo img {
    width: 230px; /* Smaller logo for small screens */
  }

  .navbar-cart {
    display: block;
    font-size: 1.8rem;
    color: white;
  }

  /* Full-Screen Mobile Menu Overlay */
  .nav-menu {
    flex-direction: column;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -250px;
    background: #ffffff;
    transition: all 0.5s ease;
    padding-top: 80px;
  }

  .nav-menu.active {
    left: 0;
    transition: all 0.5s ease;
  }

  .nav-item {
    height: 60px;
  }

  .nav-links {
    text-align: center;
    color: black;
    padding: 2rem;
    width: 100%;
    display: table;
    font-weight: bold;
  }

  .nav-links:hover {
    color: black;
    border-radius: 0;
    border-bottom: 0px;
  }

  .nav-social-link {
    color: black;
  }

  .nav-social-link:hover {
    color: black;
  }
}

/* Bottom Navbar with Search */
.navbar-bottom {
  background-color: #c52d12;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.search-button {
  padding: 10px 10px;
  font-size: 16px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  color: #c52d12;
  cursor: pointer;
}

.search-button:hover {
  background-color: #e6e6e6;
}

.navbar-cart-container {
  position: relative;
}

.basket-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff0000;
  color: white;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}