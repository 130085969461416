.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
}

.login-title {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-input {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button {
  background-color: #c52d12;
  color: white;
  border: none;
  padding: 13px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Wix Madefor Display', sans-serif;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 15px;
}

.login-button:hover {
  background-color: #a1220d;
}

.google-button,
.guest-button {
  background-color: #5a5a5a;
  color: white;
  border: none;
  padding: 13px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Wix Madefor Display', sans-serif;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 15px;
}

.google-button:hover,
.guest-button:hover {
  background-color: #3e3e3e;
}

.or-divider {
  text-align: center;
  margin: 20px 0;
}

.or-divider span {
  background-color: #fff;
  padding: 0 10px;
  font-size: 1em;
  color: #888;
}
